import React, { useMemo } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'

type Props = {
  blok: SbBlokData & ISbSectionInfoBannerV2
}

const SectionInfoBannerV2 = ({ blok }: Props) => {
  const background = useMemo(() => {
    switch (blok.background) {
      case 'beige': {
        return 'lg:bg-v2-background-beige'
      }
      case 'blue': {
        return 'lg:bg-v2-background-blue'
      }
      case 'green-dark': {
        return 'lg:bg-v2-background-green-dark lg:border-v2-foreground-static-tertiary'
      }
      case 'white':
      default: {
        return 'lg:bg-v2-background-white'
      }
    }
  }, [blok.background])

  return (
    <div className="relative overflow-hidden py-16 lg:py-24" {...storyblokEditable(blok)}>
      <div className="relative w-full lg:flex lg:h-169 lg:flex-col lg:items-center lg:justify-center">
        {/* Container for text */}
        {blok.head && blok.head[0] && (
          <div className="lg:mb:0 mx-auto mb-14 max-w-7xl px-4 lg:m-10 lg:max-w-5xl">
            <div className={`lg:rounded-5xl lg:border lg:p-20 ${background}`}>
              <ItemSectionHeadV2 blok={blok.head[0]} isTextSmall={true} background={blok.background} />
            </div>
          </div>
        )}
        <div className="h-full lg:absolute lg:-z-10 lg:w-full">
          {!!blok.background_image_mobile?.filename && (
            <BaseImageV2
              className="lg:hidden [&_img]:h-full [&_img]:w-full [&_img]:object-cover"
              src={blok.background_image_mobile.filename}
              alt={blok.background_image_mobile.alt}
              breakPointsWidthMap={{ 0: 768 }}
            />
          )}
          <BaseImageV2
            className={`[&_img]:h-full [&_img]:w-full [&_img]:object-cover ${blok.background_image_mobile?.filename ? 'hidden lg:block' : ''}`}
            src={blok.background_image.filename}
            alt={blok.background_image.alt}
            breakPointsWidthMap={{ 0: 2000 }}
          />
        </div>
      </div>
    </div>
  )
}

export default SectionInfoBannerV2
